// 消息管理
import { Request } from '@/http/request'

// 消息列表
export function messageList(parameter: any) {
  return Request.axiosInstance.post('/system/admin-message/list',parameter)
}

// 消息已读
export function messageRead(parameter: any) {
  return Request.axiosInstance.post('/system/admin-message/read',parameter)
}

// 未读消息数量 ​
export function messageUnRead(parameter: any) {
  return Request.axiosInstance.post('/system/admin-message/un-read',parameter)
}

//  全部已读 
export function allRead(parameter: any) {
  return Request.axiosInstance.post('/system/admin-message/all-read',parameter)
}

export default { messageList, messageRead, messageUnRead, allRead }