// 管理员登录
import { Request } from '@/http/request'

// 管理员登陆
export function login (parameter: any) {
  return Request.axiosInstance.post('/system/auth/login', parameter)
}

// 管理员登出
export function logout() {
  return Request.axiosInstance.post('/system/auth/logout')
}

export function getInfo() {
  return Request.axiosInstance.post('/system/admin-user/info')
}




export default { login, logout,getInfo }